import { Component, Input } from '@angular/core';
import { ValidatedOrder } from 'reg-hub-common';

@Component({
  selector: 'app-multi-search-order-review',
  templateUrl: './multi-search-order-review.component.html',
  styleUrls: ['./multi-search-order-review.component.css']
})
export class MultiSearchOrderReviewComponent {
  @Input() public order!: ValidatedOrder;
}
