import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';
import { SteppedComponent } from 'projects/reg-hub-client/src/interfaces/stepped-component';
import { ValidationProblem, Order, OrderManagerService, ValidationService, OrderGroupsService, UIConfigurationService, ValidatedOrder, eOrderType, SelectOption, EnumeratedTypesRepositoryService, OrderUIConfiguration, OrderRepositoryService } from 'reg-hub-common';
import { BehaviorSubject, catchError, EMPTY, first, forkJoin, map, switchMap, takeUntil, tap } from 'rxjs';

export interface OrderData {
  id: string,
  data: {
    order: Order,
    errors: ValidationProblem[] | null | undefined,
    serialNumberControl: AbstractControl,
    serialNumberErrors$: BehaviorSubject<string | null | undefined>
  }
}

@Component({
  selector: 'app-multi-search-criteria-validation',
  templateUrl: './multi-search-criteria-validation.component.html',
  styleUrls: ['./multi-search-criteria-validation.component.css']
})
export class MultiSearchCriteriaValidationComponent extends SteppedComponent {
  protected assetTypes: SelectOption[] = [];
  protected ordersForm!: FormGroup;

  protected data: OrderData[] = [];

  protected validating = false;
  protected saving = false;

  constructor(
    orderManagerService: OrderManagerService,
    validationService: ValidationService,
    private orderGroupsService: OrderGroupsService,
    private enumeratedTypesRepo: EnumeratedTypesRepositoryService,
    private formBuilder: FormBuilder,
    private ordersRepository: OrderRepositoryService) {
    super(orderManagerService, validationService);
  }

  override ngOnInit(): void {
    this.shouldSaveOrderOnStepChange = false;
    this.validating = true;

    super.ngOnInit();

    this.ordersForm = this.formBuilder.group({
      orders: this.formBuilder.array([])
    })

    //Need to refactor. UI configs should return the jurisdiction they are, then we can get all jurisdiction asset types and
    //give distinct ones
    this.enumeratedTypesRepo.getAssetTypesAsSelectOptions(eOrderType.assetSearch, 'SK').subscribe(jurisdictionTypes => {
      this.assetTypes = jurisdictionTypes;
    });

    this.saving$.pipe(
      takeUntil(this.onDestroy$),
      first(val => val === false),
      switchMap(() => this.orderGroupsService.getOrderGroupOrdersWithValidation(this.order.orderGroup?.id ?? '', 100)),
      map((resources: ValidatedOrder[]) => resources.filter(r => (r.validation.errors?.length ?? 0) > 0 && r.resource.orderTypeID !== eOrderType.multiAssetSearch)),
      map((resources: ValidatedOrder[]) => {
        return resources.map(r => {
          return {
            id: r.resource.id,
            data: {
              order: r.resource,
              errors: this.mapPathsToLowerCase(r.validation.errors)
            }
          } as OrderData;
        })
      }))
      .subscribe((data: OrderData[]) => {
        this.data = data;

        if (this.data.length === 0) {
          this.validating = false;
        } else {
          const ordersArray = this.ordersForm.get('orders') as FormArray;

          this.data.forEach(entry => {
            const serialNumberControl = this.formBuilder.control(entry.data.order.assets?.at(0)?.serialNumber);
            entry.data.serialNumberControl = serialNumberControl;
            entry.data.serialNumberErrors$ = new BehaviorSubject<string | null | undefined>(entry.data.errors?.filter(err => err.path?.endsWith('serialnumber'))?.at(0)?.userFriendlyMessage);

            const orderFormGroup = this.formBuilder.group({
              serialNumber: serialNumberControl
            });

            orderFormGroup.valueChanges
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(value => {
                const val = value as any;
                const serialNumber = val.serialNumber;

                entry.data.order.assets!.at(0)!.serialNumber = serialNumber;

                this.validationService.validate(entry.data.order)
                  .pipe(map(results => this.mapPathsToLowerCase(results.errors)))
                  .subscribe(results => {
                    entry.data.errors = results;
                    entry.data.serialNumberErrors$.next(results?.filter(err => err.path?.endsWith('serialnumber'))?.at(0)?.userFriendlyMessage);
                  });
              });

            ordersArray.setControl(this.data.indexOf(entry), orderFormGroup);
          });

          this.validating = false;
        }
      })
  }

  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    return StepValidationState.DefaultState;
  }

  public shouldShowForm() {
    const ordersFormArray = this.ordersForm.get('orders') as FormArray;
    return this.data.length > 0 && this.data.length === ordersFormArray.length;
  }

  public getSerialNumberControl(id: string) {
    return this.data.find(entry => entry.id === id)?.data.serialNumberControl;
  }

  public getSerialNumberErrorSubject(id: string): BehaviorSubject<string | null | undefined> {
    return this.data.find(entry => entry.id === id)?.data.serialNumberErrors$!
  }

  override onSaving(): void {
    forkJoin(this.data.map(entry => this.ordersRepository.updateOrder(`orders/${entry.data.order.id}`, entry.data.order)))
      .subscribe();
  }
}
