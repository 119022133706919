<div *ngIf="validating || saving; else loaded">
    <div class="flex-container-center margin-bottom-15">
        <mat-spinner></mat-spinner>
    </div>
    <div class="flex-container-center" *ngIf="validating">
        Validating ...
    </div>
    <div class="flex-container-center" *ngIf="saving">
        Saving ...
    </div>
</div>

<ng-template #loaded>
    <div *ngIf="shouldShowForm(); else valid">
        <form [formGroup]="ordersForm">
            <div formArrayName="orders">
                <div *ngFor="let entry of data; let i = index" [formGroupName]="i">
                    <h4>{{ entry.data.order.jurisdiction | fullJurisdictionName: entry.data.order.country }}</h4>

                    <div class="flex-container">
                        <div class="validated-form-field-container flex-container">
                            <mat-form-field class="flex-item form-field" appearance="outline">
                                <mat-label>Serial Number</mat-label>
                                <input matInput formControlName="serialNumber" name="serial-number"
                                    placeholder="Serial Number">
                            </mat-form-field>
                            <lib-error-bubble [control]="getSerialNumberControl(entry.id)"
                                [message]="getSerialNumberErrorSubject(entry.id)"
                                [showErrorsOnLoad]="true"></lib-error-bubble>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #valid>
    <lib-placeholder iconName="check_circle" iconClass="icon-success" message="No invalid criteria"
        [condition]="true"></lib-placeholder>
</ng-template>