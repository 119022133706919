<div *ngIf="loading; else loaded">
    <div class="flex-container-center margin-bottom-15">
        <mat-spinner></mat-spinner>
    </div>
    <div class="flex-container-center">
        Saving ...
    </div>
</div>

<ng-template #loaded>
    <div *ngFor="let order of orders">
        <app-multi-search-order-review [order]="order"></app-multi-search-order-review>
    </div>
</ng-template>