import { Component, Input, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs';
import { ConfigurationState, MessagesStateService, NavItem, OrderRepositoryService } from 'reg-hub-common';
import { OrdersHubService } from '../../services/hubs/orders-hub/orders-hub.service';
import { ClientAuthService } from '../../services/auth/client-auth.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent {
  @Input() isSidenavOpen: boolean = true;

  @ViewChild(ToastContainerDirective, { static: true }) toastContainer!: ToastContainerDirective

  selectedItem = '';
  isSearching = false;
  disableRipple = true;
  unreadMessageCount: number = 0;

  // This will come from a DB call to which nav items a user is allowed to see
  navItems: NavItem[] = [
    { label: 'Dashboard', icon: 'home', link: '/dashboard' },
    { label: 'Order History', icon: 'list_alt', link: '/orders' },
    { label: 'Messages', icon: 'message', link: '/messages' },
    { label: 'Reports', icon: 'summarize', link: '/reports' },
    { label: 'Accounting', icon: 'payment', link: '/accounting' }
  ];

  requestItems: NavItem[] = [
    { label: 'PPSA Registration', icon: 'draw', link: '/new-registration' },
    { label: 'PPSA Search', icon: 'search', link: '/new-search' },
    { label: 'Business Search', icon: 'corporate_fare', link: '/new-business-search' }
  ];

  helpItems: NavItem[] = [
    { label: 'Information', icon: 'quiz', link: '/information-documents' },
    { label: 'Contact Us', icon: 'support_agent', link: '/contact-us' },
    { label: 'System Events', icon: 'event_note', link: '/system-events' },
  ];

  constructor(
    private messageService: ToastrService,
    //private configurationState: ConfigurationState,
    private router: Router,
    private ordersRepo: OrderRepositoryService,
    private messagesStateService: MessagesStateService
    ) { }

  ngOnInit(): void {
    this.messageService.overlayContainer = this.toastContainer;
    this.setNavItems();

    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(() => this.messageService.clear());

    // Subscribe to unread message updates from the service
    this.messagesStateService.unreadMessagesCount$.subscribe(count => {
      this.unreadMessageCount = count;
    });
  }

  ngAfterViewInit() {
    this.ordersRepo.getOrdersWithUnreadClientMessages().subscribe(result => {
      this.messagesStateService.setUnreadMessageCount(result.total);
    });
  }

  private setNavItems() {
    //TEMPORARY FIX
    // this.configurationState.hasUserManagementAccess().then(hasAccess => {
    //   if (hasAccess) {
        this.navItems = this.navItems.concat([{ label: 'Management', icon: 'settings', link: '/management' }]);
    //   }
    // });
  }

  handleClick(selectedItem: NavItem) {
    this.selectedItem = selectedItem.label;
  }

  onSearch(searchText: string) {
    this.isSearching = true;

    this.router.navigate(['orders', searchText]);
  }
}
