import { Component } from '@angular/core';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';
import { SteppedComponent } from 'projects/reg-hub-client/src/interfaces/stepped-component';
import { ValidationProblem, Order, OrderManagerService, ValidationService, OrderGroupsService, ValidatedOrder, Validation, eOrderType, UIConfigurationService } from 'reg-hub-common';
import { map } from 'rxjs';

@Component({
  selector: 'app-multi-search-review',
  templateUrl: './multi-search-review.component.html',
  styleUrls: ['./multi-search-review.component.css']
})
export class MultiSearchReviewComponent extends SteppedComponent {
  protected orders: ValidatedOrder[] = [];
  protected loading = false;
  protected validating = false;

  constructor(
    orderManagerService: OrderManagerService,
    validationService: ValidationService,
    private orderGroupsService: OrderGroupsService) {
    super(orderManagerService, validationService);
  }

  override ngOnInit(): void {
    this.shouldSaveOrderOnStepChange = false;
    this.loading = true;
    this.validating = true;
    super.ngOnInit();

    this.orderGroupsService.getOrderGroupOrdersWithValidation(this.order.orderGroup?.id ?? '', 100)
      .pipe(map((resources: ValidatedOrder[]) => resources.filter(r => r.resource.orderTypeID !== eOrderType.multiAssetSearch)))
      .subscribe(orders => {
        this.validationService.currentValidation$.next({
          errors: orders.flatMap(o => o.validation.errors ?? [])
        });

        this.orders = orders;
        this.loading = false;
      });
  }

  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    return StepValidationState.DefaultState;
  }
}
