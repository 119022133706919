import { MultiSearchCriteriaValidationComponent } from "projects/reg-hub-client/src/app/orders/multi-search-criteria-validation/multi-search-criteria-validation.component";
import { MultiSearchCriteriaComponent } from "projects/reg-hub-client/src/app/orders/multi-search-criteria/multi-search-criteria.component";
import { MultiSearchReviewComponent } from "projects/reg-hub-client/src/app/orders/multi-search-review/multi-search-review.component";
import { Step, StepValidationState } from "projects/reg-hub-client/src/interfaces/step";
import { Stepper } from "projects/reg-hub-client/src/interfaces/stepper";

export class MultiAssetSearchStepper implements Stepper {

    steps: Step[];

    constructor() {
        this.steps = [
            { title: "Search Criteria", content: MultiSearchCriteriaComponent, validationState: StepValidationState.DefaultState },
            { title: 'Criteria Validation', content: MultiSearchCriteriaValidationComponent, validationState: StepValidationState.DefaultState },
            { title: "Review", content: MultiSearchReviewComponent, validationState: StepValidationState.DefaultState }
        ]
    }
}