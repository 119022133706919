import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Order, Party, OrderHistoryComponent, OrderUIConfiguration, eOrderType, eOrderStatusType, OrderMetaDataRepositoryService } from 'reg-hub-common';
import { Constants } from '../../../../src/constants';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {
  @Input() order!: Order
  @Input() uiConfiguration!: OrderUIConfiguration;
  @Input() loading: boolean = true;
  orderTypeName: string = ""

  debtors!: Observable<Party[]>;
  securedParties!: Observable<Party[]>;
  dealers!: Observable<Party[]>;
  registeringAgents!: Observable<Party[]>;

  debtorsTitle: string = "Debtors";
  securedPartiesTitle: string = "Secured Parties";
  dealersTitle: string = "Dealers";
  registeringAgentsTitle: string = "Registering Agents";
  showEstate: boolean = false;
  requestingUser: string = "";

  constructor(private dialog: MatDialog,
    private orderMetaDataService: OrderMetaDataRepositoryService) { }

  ngOnInit(): void {
    this.orderTypeName = this.order.orderType.name;
    this.debtors = of(this.getDebtors());
    this.securedParties = of(this.getSecuredParties());
    this.dealers = of(this.getDealers());
    this.registeringAgents = of(this.getRegisteringAgents());
    this.getOrderMetaData()
  }

  getOrderMetaData() {
    this.orderMetaDataService.getOrderMetaData(null, this.order.userGroupID, this.order.userID, null)
      .subscribe(metaData => {
        this.requestingUser = `${metaData.requestingUserFullName} (${metaData.requestingUserName})`;
      })
  }

  openOrderHistory() {
    this.dialog.open(OrderHistoryComponent, { data: this.order });
  }

  isSearchOrder(): boolean {
    return this.order.orderTypeID.toString() == "AssetSearch"
      || this.order.orderTypeID.toString() == "DebtorSearch"
      || this.order.orderTypeID.toString() == "CertifiedDebtorSearch";
  }

  isMultiSearchOrder(): boolean {
    return this.order.orderTypeID === eOrderType.multiAssetSearch;
  }

  isDischarge(): boolean {
    return this.order.orderTypeID.toString() == "Discharge"
      || this.order.orderTypeID.toString() == "BasicDischarge";
  }

  showLifetime(): boolean {
    return (this.order.lien != null && 
      (this.order.lien?.isInfiniteTerm ?? false) === false && 
      !this.isDischarge() &&
      this.order.orderStatusTypeID != "Cancelled");
  }

  getDebtors(): Party[] {
    return this.order.parties?.filter((party) => Constants.isDebtor(party.partyTypeID)) ?? [];
  }

  getSecuredParties(): Party[] {
    return this.order.parties?.filter((party) => Constants.isSecuredParty(party.partyTypeID)) ?? [];
  }

  getDealers(): Party[] {
    return this.order.parties?.filter((party) => Constants.isDealer(party.partyTypeID)) ?? [];
  }

  getRegisteringAgents(): Party[] {
    return this.order.parties?.filter((party) => Constants.isRegisteringAgent(party.partyTypeID)) ?? [];
  }

  getStatusIcon() {
    switch (this.order.orderStatusTypeID) {
      case eOrderStatusType.historical:
        return 'book_5';
      case eOrderStatusType.historicalDraft:
        return 'menu_book';
      case eOrderStatusType.complete:
        return 'check_circle_outline';
      case eOrderStatusType.pending:
        return 'schedule';
      case eOrderStatusType.invalid:
        return 'error_outline';
      case eOrderStatusType.cancelled:
        return 'block';
      default: return '';
    }
  }

  getStatusIconColor() {
    switch (this.order.orderStatusTypeID) {
      case eOrderStatusType.complete:
        return { color: '#8BA77D' };
      case eOrderStatusType.pending:
      case eOrderStatusType.historical:
      case eOrderStatusType.historicalDraft:
        return { color: '#2B73A8' };
      case eOrderStatusType.invalid:
      case eOrderStatusType.cancelled:
        return { color: '#FB2C38' };
      default: return { color: 'black' };
    }
  }
}
