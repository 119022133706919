import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { JurisdictionInfo } from '../../interfaces/jurisdictions/jurisdiction-info';
import { eOrderType } from '../../enums';
import { EnumeratedTypesRepositoryService } from '../../services/enumerated-types/enumerated-types-repository.service';

@Component({
  selector: 'lib-multi-jurisdiction-select',
  templateUrl: './multi-jurisdiction-select.component.html',
  styleUrls: ['./multi-jurisdiction-select.component.css']
})
export class MultiJurisdictionSelectComponent implements OnInit {
  @Input() public orderTypes: eOrderType[] = [];
  @Input() public initialJurisdictions: string[] = [];
  @Output() public selectedJurisdictions = new EventEmitter<JurisdictionInfo[]>();

  protected selectionJurisdictions: JurisdictionInfo[] = [];
  private _map = new Map<string, boolean>();

  private hasUsedInitialJurisdictions = false;

  constructor(private enumeratedTypesRepo: EnumeratedTypesRepositoryService) {}

  ngOnInit(): void {
    this.enumeratedTypesRepo.getOrderTypeJurisdictions(this.orderTypes)
      .subscribe(response => response.forEach(type => {
        //Should only be one for now (CA)
        this.selectionJurisdictions = type.jurisdictions;

        if(!this.hasUsedInitialJurisdictions && this.initialJurisdictions) {
          this.selectionJurisdictions.forEach(juris => {
            this._map.set(juris.jurisdictionCode, this.initialJurisdictions.includes(juris.jurisdictionCode));
          });
    
          this.hasUsedInitialJurisdictions;
        }
      }))
  }

  isChecked(jurisdiction: string) {
    return this._map.get(jurisdiction) === true;
  }

  onCheckedChange(event: MatSlideToggleChange, jurisdiction: JurisdictionInfo): void {
    this._map.set(jurisdiction.jurisdictionCode, event.checked === true);
    this.selectedJurisdictions.emit(this.getSelectedJurisdictions());
  }

  getChipLabel(jurisdictionCode: string): string {
    return jurisdictionCode === 'CW' ? 'FED' : jurisdictionCode;
  }

  getSlideToggleLabel(jurisdictionFullName: string): string {
    return jurisdictionFullName === 'Country-Wide' ? 'Federal' : jurisdictionFullName;
  }

  selectAllToggles(): void {
    for (const key of [...this._map.keys()]) {
      this._map.set(key, true);
    }

    this.selectedJurisdictions.emit(this.getSelectedJurisdictions());
  }

  deselectAllToggles(): void {
    for (const key of [...this._map.keys()]) {
      this._map.set(key, false);
    }

    this.selectedJurisdictions.emit(this.getSelectedJurisdictions());
  }

  protected getSelectedJurisdictions(): JurisdictionInfo[] {
    return this.selectionJurisdictions.filter(j => this._map.get(j.jurisdictionCode) === true);
  }

  protected disableSelectAll() {
    return [...this._map.values()].every(val => val === true);
  }

  protected disableDeselectAll() {
    return [...this._map.values()].every(val => val === false);
  }
}
