import { Component, OnInit } from '@angular/core';
import { Order } from '../../interfaces/orders/order'
import { OrderManagerService } from '../../services/order-manager/order-manager.service';
import { OrderGroupsService } from '../../services/order-groups/order-groups.service';
import { Router } from '@angular/router';
import { BehaviorSubject, map } from 'rxjs';
import { eOrderType } from '../../enums';

@Component({
  selector: 'lib-multi-search-orders',
  templateUrl: './multi-search-orders.component.html',
  styleUrls: ['./multi-search-orders.component.css']
})
export class MultiSearchOrdersComponent implements OnInit {
  protected order!: Order;
  protected orders = new BehaviorSubject<Order[]>([]);

  displayedColumns: string[] = [
    'orderTypeID', 
    'orderStatusTypeID',
    'country',
    'jurisdiction',
    'documents',
    'view'
  ];

  constructor(
    private orderManagerService: OrderManagerService,
    private orderGroupService: OrderGroupsService,
    private router: Router) { }

  ngOnInit(): void {
    this.order = this.orderManagerService.currentOrder.value;

    if (this.order.orderGroup?.id) {
      this.orderGroupService.getOrderGroupOrders(this.order.orderGroup?.id ?? '', 100)
        .pipe(map(orders => orders.filter(o => o.orderTypeID !== eOrderType.multiAssetSearch)))
        .subscribe(orders => {
          this.orders.next(orders);
        });
    }
  }

  protected viewOrder(order: Order, newTab: boolean) {
    if (newTab) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['orders', order.id])
      );

      window.open(url, '_blank');
    }
    else {
      this.router.navigate(['orders', order.id])
    }
  }
}
